<template>
    <div>
        <jy-query :model="form" ref="form" labelWidth="100" class="query_area" size="small">
            <jy-query-item label="分拣中心名称" prop="unitId">
                <el-select v-model="form.unitId">
                    <el-option v-for="u in unitList" :key="u.unitId" :value="u.unitId" :label="u.cname"></el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item label="年份" prop="year">
                <el-date-picker value-format="yyyy" v-model="form.year" type="year" placeholder="选择年"></el-date-picker>
            </jy-query-item>
            <jy-query-item label="所属行政区域" prop="regionId">
                <div @click="selectRegion">
                    <el-input placeholder="请选择" v-model="form.regionNa"></el-input>
                </div>
            </jy-query-item>
            <jy-query-item label="固废类别" prop="wasteT">
                <el-select v-model="form.wasteT">
                    <el-option v-for="t in wasteTypes" :key="t.wasteTId" :label="t.cName" :value="t.wasteTId"></el-option>
                </el-select>
            </jy-query-item>
            <template slot="rightCol">
                <el-button size="small" type="primary" @click="oncheck" v-if="btnexist('wastesStatisticsCenterRubYearList')">查询</el-button>
                <el-button size="small" type="primary" @click="resetForm('form')" v-if="btnexist('wastesStatisticsCenterRubYearRest')">重置</el-button>
                <el-row>
                    <export-excel
                        v-if="btnexist('wastesStatisticsCenterRubYearExport') && false"
                        baseUrl="/iwastes-admin"
                        :params="form"
                        url="/stat/unit/export"
                        fileName="分拣中心年报表"
                        fileType=".xls"
                        size="small"
                    ></export-excel>
                </el-row>
            </template>
        </jy-query>
        <div>
            搜索结果合计：记录{{ total }}条，清运总重量{{ statisticsInfo.rWeight }}{{ unitName }}，环服上门清运重量{{ statisticsInfo.scrWeight
            }}{{ unitName }}，企业自送重量{{ statisticsInfo.rerWeight }}{{ unitName }}，入库量{{ statisticsInfo.inSum }}{{ unitName }}，出库处置量{{
                statisticsInfo.outSum
            }}{{ unitName }}，实际处置量{{ statisticsInfo.dealSum }}{{ unitName }}
        </div>
        <div class="jy-table" v-loading="loading">
            <jy-table :data="dataList">
                <jy-table-column label="序号" type="index" width="55"></jy-table-column>
                <jy-table-column label="分拣中心" min-width="150">
                    <template v-slot="scope">{{ formatUnit(scope.row.unitId) }}</template>
                </jy-table-column>
                <jy-table-column label="所属行政地区" width="150" prop="regionNa"></jy-table-column>
                <jy-table-column label="固废类别"  min-width="120">
                    <template>{{ wasteTName }}</template>
                </jy-table-column>
                <jy-table-column label="统计时间" prop="statTime" min-width="120"></jy-table-column>
                <jy-table-column label="清运总重量" width="120" prop="rWeight">
                    <template v-slot="scope">{{ scope.row.rWeight }}{{ unitName }}</template>
                </jy-table-column>
                <jy-table-column label="上一年同期" width="120">
                    <template v-slot="scope">{{ scope.row.rWeightPre }}{{ unitName }}</template>
                </jy-table-column>
                <jy-table-column label="与上一年同期变化" width="150">
                    <template v-slot="scope">
                        {{ formatRatio(scope.row.percent) }}
                    </template>
                </jy-table-column>
                <jy-table-column label="环服上门清运重量" width="150">
                    <template v-slot="scope">{{ scope.row.scrWeight }}{{ unitName }}</template>
                </jy-table-column>
                <jy-table-column label="企业自送重量" width="150">
                    <template v-slot="scope">{{ scope.row.rerWeight }}{{ unitName }}</template>
                </jy-table-column>
                <jy-table-column label="入库量">
                    <template v-slot="scope">{{ scope.row.inSum }}{{ unitName }}</template>
                </jy-table-column>
                <jy-table-column label="出库处置量" width="150">
                    <template v-slot="scope">{{ scope.row.outSum }}{{ unitName }}</template>
                </jy-table-column>
                <jy-table-column label="实际处置量" width="150">
                    <template v-slot="scope">{{ scope.row.dealSum }}{{ unitName }}</template>
                </jy-table-column>
                <jy-table-column label="回收利用率" width="150">
                    <template v-slot="scope">{{ formatRecycleRatio(scope.row.outSum, scope.row.rWeight) }}</template>
                </jy-table-column>
            </jy-table>
            <jy-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pageIndex"
                :page-size="pageSize"
                :total="total"
            ></jy-pagination>
        </div>

        <region-tree ref="region" @select="addRegion"></region-tree>
    </div>
</template>

<script>
    import ExportExcel from "@/components/pages/admin/common/export";
    import RegionTree from "@/components/pages/admin/common/dialog-tree/region_tree.vue";

    import { btnMixins } from "@/common/js/button.mixin";
    export default {
        mixins: [btnMixins],
        created() {
            this.initDicts();

            this.form.year = new Date().getFullYear().toString();
        },
        methods: {
            formatUnit(key) {
                const u = this.unitList.find(v => v.unitId === key);
                return u ? u.cname : "-";
            },
            formatRatio(p){
                if (p === undefined) {
                    return '-'
                }else{
                    p = (p *100).toFixed(2) + '%'
                }
                return p
            },
            formatRecycleRatio(o, a) {
                if (a === 0) {
                    return "0%";
                } else {
                    return ((1 - o / a) * 100).toFixed(2) + "%";
                }
            },
            initDicts() {
                this.$http
                    .post("/unitInfo/queryUnitList", {
                        cName: "",
                        unitType: "3"
                    })
                    .then(res => {
                        this.unitList = res.detail;
                    });
                this.$http.post("/wasteType/listAll").then(res => {
                    this.wasteTypes = res.detail;
                    this.form.wasteT = this.wasteTypes[0].wasteTId;
                    this.getList()
                });
            },
            selectRegion() {
                this.$refs.region.init();
            },
            addRegion(data) {
                this.form.regionId = data.regionId;
                this.form.regionNa = data.regionNa;
            },
            oncheck() {
                this.pageIndex = 1;
                this.getList();
                console.log("更新");
            },
            getList() {
                this.loading = true;
                let url = "/stat/unit/gather";
                this.$http
                    .post(
                        url,
                        {
                            ...this.form,
                            regionId: this.form.regionId || undefined,
                            pageIndex: this.pageIndex,
                            pageSize: this.pageSize
                        },
                        { isRequestParam: false }
                    )
                    .then(({ detail }) => {
                        this.dataList = detail.list.map(v => {
                            return {
                                ...v.rWeight,
                                ...v.cStock,
                                ...v.unitInfo,
                                statTime: v.statTime
                            };
                        });
                        this.total = detail.total;
                        this.statisticsInfo = {
                            ...detail.rWeight,
                            ...detail.cStock
                        };
                    })
                    .catch(err => {
                        console.log(err)
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            add() {
                this.$emit("switch");
            },
            // 重置
            resetForm(formName) {
                const wasteT = this.form.wasteT;
                this.$refs[formName].resetFields();
                this.form.year = new Date().getFullYear().toString();
                this.form.regionNa = "";
                this.form.wasteT = wasteT;
                this.oncheck();
            },
            // 分页
            handleSizeChange(val) {
                this.pageSize = val;
                this.getList();
            },
            handleCurrentChange(val) {
                this.pageIndex = val;
                this.getList();
            }
        },
        computed: {
            unitName() {
                if (this.form.wasteT && this.wasteTypes) {
                    const one = this.wasteTypes.find(v => v.wasteTId === this.form.wasteT);
                    return one ? one.unit : "kg";
                }
                return "kg";
            },
            wasteTName() {
                if (this.form.wasteT && this.wasteTypes) {
                    const one = this.wasteTypes.find(v => v.wasteTId === this.form.wasteT);
                    return one ? one.cName : "-";
                }
                return "-";
            }
        },
        data() {
            return {
                loading: false,
                dataList: [],
                total: 0,

                form: {
                    regionId: "",
                    regionNa: "",
                    unitId: "",
                    year: "",
                    wasteT: ""
                },
                statisticsInfo: {},
                pageIndex: 1,
                pageSize: 10,
                unitList: [],
                wasteTypes: [],

                btnMenuId: "wastesStatisticsCenterRubYear"
            };
        },
        components: {
            ExportExcel,
            RegionTree
        }
    };
</script>
<style scoped>
    .query_area {
        margin-top: 10px;
    }
</style>
