<template>
    <div>
        <jy-dialog :modal="modal" title="选择行政区域" :visible.sync="dialogVisible" custom-class="dialog_tree" :width="global.dialogTreeWidth" @close="cancel">
            <el-tree
                :default-checked-keys="defaultKeys"
                ref="institutions_tree"
                :show-checkbox="check"
                :props="defaultProps"
                accordion
                @node-click="save"
                node-key="regionId"
                lazy
                :load="loadNode"
                :check-strictly="checkStrictly"
                @check="checkChange"
            ></el-tree>
            <div v-if="check" class="tree_check_select">
                <el-button size="small" @click="cancel">取消</el-button>
                <el-button type="primary" size="small" @click="save">确定</el-button>
            </div>
        </jy-dialog>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                dialogVisible: false,
                defaultProps: {
                    children: "children",
                    label: "regionNa"
                },
                check: false,
                defaultKeys: [],
                pid: 0,
            };
        },
        props: {
            title: {
                type: String,
                default: ""
            },
            modal: {
                type: Boolean,
                default: true
            },
            checkStrictly: {
                type: Boolean,
                default: false,
            },
        },
        methods: {
            init(options) {
                this.dialogVisible = true;
                if (options) {
                    this.check = options.check;
                    this.pid = options.pid || 100000;
                    this.defaultKeys = options.defaultKeys || [];
                } else {
                    this.check = false;
                }
            },

            collapseAll() {
                for (let node of this.$refs.institutions_tree.store._getAllNodes()) {
                    node.expanded = false;
                }
            },

            loadNode(node, resolve) {
                if (node.data) {
                    this.pid = node.data.regionId;
                }
                this.$http.post("/base/region/queryRegionByPid", { pid: this.pid }, { type: "format" }).then(res => {
                    resolve(res.detail);
                });
            },

            save(data) {
                if (data.level === 0) {
                    return;
                }
                if (this.check) {
                    this.$emit("select", this.$refs.institutions_tree.getCheckedNodes(false, false));
                    this.dialogVisible = false;
                } else {
                    this.$emit("select", data);
                    this.dialogVisible = false;
                }
            },

            cancel() {
                this.dialogVisible = false;
                this.$refs.institutions_tree.setCheckedNodes([])
                this.collapseAll()
            },

            checkChange(node, check) {
                this.$emit('change', this.$refs.institutions_tree, node, check);
            }
        }
    };
</script>
<style scoped>
    .tree_check_select {
        text-align: center;
        margin-top: 20px;
    }
</style>
